
html, body {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.my-loader {
    display: none;
}

.my-loader-inner {
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: rgba(0, 0, 0, .15) url('../img/my-loader.gif') 50% 50% no-repeat;*/
    background: rgba(255, 255, 255, .50);
}

.my-loader i {
    color: #c3c3c3;
    font-size: 48px;
}

body.loading .my-loader {
    display: block;
}

.bg-theme {
    background-color: $bg-theme !important;
}

a {
    color: $body-color;
    text-decoration: none;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    text-shadow: rgba(0, 0, 0, .15) 0 0 1px;
}

h6, .h6 {
    //font-size: 12px;
    font-weight: bold;
}

.form-group {
    display: block;
    margin-bottom: 1rem;
}

.text-green-glow {
    color: green;
    text-shadow: 0 0 10px green, 0 0 20px green;
}

input[type="date"] {
    position: relative;
    overflow: hidden;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

.avatar {
    position: relative;
    display: inline-block;
    width: 40px;
    white-space: nowrap;
    border-radius: 1000px;
    vertical-align: bottom;

    i {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 10px;
        height: 10px;
        border: 2px solid #fff;
        border-radius: 100%;
    }

    img {
        width: 100%;
        max-width: 100%;
        height: auto;
        border: 0 none;
        border-radius: 1000px;
    }
}

html,
body {
    overflow-x: hidden; /* Prevent scroll on narrow devices */
}

body {
    padding-top: 106px;
}

.text-white a.nav-link {
    color: #fff;
}

.top-nav .nav-link {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.required:after {
    content: " ✽";
    color: red;
}

@media (max-width: 991.98px) {
    .offcanvas-collapse {
        position: fixed;
        top: 56px; /* Height of navbar */
        bottom: 0;
        left: 100%;
        width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        overflow-y: auto;
        visibility: hidden;
        background-color: #343a40;
        transition: transform .3s ease-in-out, visibility .3s ease-in-out;
    }
    .offcanvas-collapse.open {
        visibility: visible;
        transform: translateX(-100%);
    }
}

.nav-underline {
    .nav-link {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: .875rem;
        color: #6c757d;
        white-space: nowrap;
    }

    .nav-link:hover {
        color: #007bff;
    }

    .active {
        font-weight: 500;
        color: #343a40
    }
}

/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }
    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }
    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu > li:hover {
        background-color: #f1f1f1
    }
    .dropdown-menu > li:hover > .submenu {
        display: block;
    }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
    .dropdown-menu .dropdown-menu {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }
}

/* ============ small devices .end// ============ */

.dropdown-menu {
    border-radius: 0;
    border: 0;
    box-shadow: 0 4px 6px #00000025;
}

.list-group.aside {
    border-radius: 1;

    a {
        padding-left: 40px;
        color: $body-color;

        i {
            position: absolute;
            top: calc(50% - 0.5rem);
            left: 15px;
        }
    }
}

.bg-danger,
.bg-info,
.bg-warning,
.bg-primary,
.bg-success {
    .text-muted {
        color: #eee !important;
    }
}

.bg-warning {
    //color: var(--text-dark) !important;
}

.btn-primary,
.btn-danger,
.btn-warning,
.btn-success,
.btn-info,
.btn-dark {
    color: #ffffff;
}

.btn-info {
    color: #000;
    background-color: #30beff;
    border-color: #30beff
}

.btn-info:hover {
    color: #000;
    background-color: #64ceff;
    border-color: #64ceff
}

.btn-default {
    color: #000;
    background-color: #a8b6bc;
    border-color: #a8b6bc
}

.btn-default:hover {
    color: #000;
    background-color: #bec8cd;
    border-color: #bec8cd
}

.btn-check:focus + .btn-default, .btn-default:focus {
    color: #000;
    background-color: #bec8cd;
    border-color: #bec8cd;
    box-shadow: 0 0 0 .25rem rgba(143, 155, 160, .5)
}

.btn-check:active + .btn-default, .btn-check:checked + .btn-default, .btn-default.active, .btn-default:active, .show > .btn-default.dropdown-toggle {
    color: #000;
    background-color: #c6d0d3;
    border-color: #c6d0d3
}

.btn-check:active + .btn-default:focus, .btn-check:checked + .btn-default:focus, .btn-default.active:focus, .btn-default:active:focus, .show > .btn-default.dropdown-toggle:focus {
    box-shadow: 0 0 0 .25rem rgba(143, 155, 160, .5)
}

.btn-default.disabled, .btn-default:disabled {
    color: #000;
    background-color: #a8b6bc;
    border-color: #a8b6bc
}

.btn-theme {
    background-color: $bg-theme;
    color: #ffffff;
}

.btn-theme:hover {
    background-color: $bg-theme-hover !important;
    color: #ffffff !important;
}

.right-side table:not(#total-table) td:first-child {
    width: 40% !important;
}

.step-title {
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

#express-aside {
    .list-group-item {
        display: flex;
        padding-top: 15px;
        padding-bottom: 15px;

        .step-number {
            background-color: var(--bs-dark);
            color: #fff;
            font-size: 1.5rem;
            border-radius: 50%;
            display: block;
            text-align: center;
            width: 48px;
            height: 48px;
            line-height: 48px;
            padding: 0;
            margin: 0 1rem 0 0;
        }

        .step-desc {
            display: flex;
            flex-direction: column;

            p {
                margin-bottom: 0;
            }
        }
    }

    .current.list-group-item {
        background-color: $bg-theme;
        color: $body-color;

        .step-number {
            background-color: #ffffff;
            color: $bg-theme;
        }

        .step-desc {
            color: #fff;
        }

        .text-muted {
            color: #fff !important;
        }
    }
}

.help-block {
    color: $red;
}

.app-express {
    form {

    }
}

.page {
    padding: 1.5rem;
}

.card:not(.flush) {
    padding: 1.5rem;
    margin-bottom: 3rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important
}

.card-title,
.slide-title {

}

.header-widget {
    .lead,
    .card-title,
    .slide-title {

    }
}

.page-aside-title {
    padding: 1rem 1.0rem 0.5rem 0;
    font-weight: bold;
}

.dropify-wrapper .dropify-message span.file-icon p {
    font-size: 14px;
}

.list-group-item.active {
    background-color: var(--bs-info) !important;
    color: #fff !important;
}


.diff-holder li:before {
    padding-right: 20px;
    margin-left: 5px;
}

.diff-holder li {
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    margin-bottom: 1px;
}

.diff-holder li.removed:before {
    content: "-";
}

.diff-holder li.added:before {
    content: "+";
}

.diff-holder li.removed {
    background: #fff6f6;
    color: #fa424a;
    font-size: 12px;
}

.diff-holder li.added {
    background: #edf9ee;
    color: #3c763d;
    font-size: 12px;
}

.diff-holder {
    border: 1px solid #eee;
    padding: 5px;
    background: #ffffff;
    margin: 10px 0;
    list-style: none;
}

.diff-holder li:before {
    padding-right: 20px;
    margin-left: 5px;
}

.diff-holder li {
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    margin-bottom: 1px;
}

.diff-holder li.removed:before {
    content: "-";
}

.diff-holder li.added:before {
    content: "+";
}

.diff-holder li.removed {
    background: #fff6f6;
    color: #fa424a;
    font-size: 12px;
}

.diff-holder li.added {
    background: #edf9ee;
    color: #3c763d;
    font-size: 12px;
}

.text-danger {
    color: #b20000 !important;
}

.swal-overlay {
    z-index: 9999999;
}

.page-header {
    margin-bottom: 1.5rem;
}

.form-check-label {
    margin-right: 1rem;
}

.swal-content {
    text-wrap: balance;
}
.text-monospace {
    font-family: "Consolas", monospace;
    font-size: 110%;
}
