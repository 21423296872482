// Body
$body-bg: #f1f4f5;
$bg-theme: #4397e6;
$bg-theme-hover: #024179;
$body-color: #555555;
$btn-color:#eee;

$primary:#4397e6;

// Typography
//$font-family-sans-serif: 'Nunito', sans-serif;
//$font-family-sans-serif: 'Roboto', sans-serif;
$font-family-sans-serif: 'Nunito Sans', sans-serif;
$font-size-base: 0.86rem;
$line-height-base: 1.6;

// nav
$nav-link-padding-x: 1rem;
$nav-link-padding-y: 1rem;

$list-group-active-bg: #f1f4f5;
$list-group-active-color: $bg-theme;

// Cards
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-title-spacer-y: 0.5rem;
$card-border-width: 1px;
$card-border-color: transparent;
$card-border-radius: 0.375rem;
$card-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
$card-inner-border-radius: calc(0.375rem - 1px);
$card-cap-padding-y: 0.5rem;
$card-cap-padding-x: 1rem;
$card-cap-bg: transparent;
$card-bg: #fff;
$card-img-overlay-padding: 1rem;
$card-group-margin: 0.75rem;

$offcanvas-width:640px;

