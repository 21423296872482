// Fonts
@import url('https://fonts.bunny.net/css?family=nunito-sans:300,400,700');
@import url('https://fonts.bunny.net/css?family=Nunito:300,400,700');
@import url('https://fonts.bunny.net/css?family=Roboto:300,400,700');

// Variables
@import 'variables';

// Do not forget to mix.copy the fonts directory from node_modules into the public folder
$dropify-font-path: '/fonts';
@import 'node_modules/dropify/src/sass/dropify';

$primary: #4397e6;
$success: #3c80c2;
$secondary: #35699e;
$warning: #2e547d;
$info: #263f5c;
$danger: #1d2c3e;
$dark: #131922;

$theme-colors: (
    "primary":$primary,
    "success":$success,
    "secondary":$secondary,
    "warning":$warning,
    "info":$info,
    "danger":$danger,
    "dark":$dark
);

@import 'bootstrap/scss/bootstrap';
@import 'node_modules/select2/src/scss/core';
@import 'node_modules/select2-bootstrap-5-theme/src/select2-bootstrap-5-theme';
@import 'node_modules/select2-bootstrap-5-theme/src/include-all';
@import 'fontawesome-free-v6/css/all.min.css';

@import "styles";
